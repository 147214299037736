import React from "react";
import "./Footer.css";
const Footer = () => {
  return (
    <div className="Footer">
      <p>2024 | &copy; MTN Tree Fellers </p>
      <p>7077 Cosmo city, Cosmo City, Randburg, 2188</p>
    </div>
  );
};

export default Footer;